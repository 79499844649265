.product-heading {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-weight: bold;
}

.child-img-col {
  height: auto;
  padding: 1rem;
}

.child-img-wrapper {
  border: 3px solid #ffc451;
  padding: 1rem;
}

.child-img {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: contain;
  mix-blend-mode: multiply;
}
